import React, { useState } from "react"
import Sticky from 'react-stickynode';
import '../../main.scss'
import './layout.scss'

export default function Layout({ children }) {
	const navItems = [
		{
			url: '/quem-somos',
			text: 'Quem somos'
		},
		{
			url: '/pedagogia-florenca',
			text: 'Pedagogia Florença'
		},
		{
			url: '/colegio-florenca',
			text: 'Colégio Florença'
		},
		{
			url: '/cursos',
			text: 'Nossos cursos'
		},
		{
			url: '/blog',
			text: 'Editora/Blog Florença'
		},
		{
			url: '/loja',
			text: 'Loja pedagógica'
		},
		{
			url: '/contato',
			text: 'Contato'
		}
	]

	const addresses = [
		{
			attribute: 'mailto:contato@pedagogiaflorenca.com.br',
			text: 'contato@pedagogiaflorenca.com.br'
		},
		{
			attribute: 'tel:+4832331686',
			text: '(48) 3233-1686'
		},
		{
			attribute: 'tel:+48991427836',
			text: '(48) 9 9142-7836'
		}
	]

	const [navOpen, setNavOpen] = useState(false);

	function toggleNav(s) {
		setNavOpen(!navOpen)
	}

	return (
		<div className="wrapper">
			<Sticky>
				<header className="header">
					<div className="header__inner">
						<h1 className="header__heading">
							<a className="header__link" href="/">
								<img className="header__logo" src="/" alt="Logo" />
							</a>
						</h1>
						<nav className="nav">
							<button className="nav__toggle" onClick={toggleNav} aria-label={`${navOpen === false ? 'abrir menú' : 'fechar menú'}`} />
							<ul className={`nav__list ${navOpen === true ? 'nav__list--open' : ''}`}>
								{navItems.map(el =>
									<li className="nav__item" key={el.url}>
										<a href={el.url} className="nav__link">
											<span>
												<span>
													{el.text}
												</span>
											</span>
										</a>
									</li>
								)}
							</ul>
						</nav>
					</div>
				</header>
			</Sticky>
			{children}
			<footer className="footer">
				<div className="footer__inner">
					<img className="footer__logo footer__section" src="/" alt="Logo" />
					<div className="footer__section-wrapper">
						<div className="footer__section">
							<span className="footer__title">Contato</span>
							<address className="addresses">
								<ul className="addresses__list">
									{addresses.map(el =>
										<li className="addresses__item" key={el.attribute}>
											<a className="" href={el.attribute} key={el.attribute}>{el.text}</a>
										</li>
									)}
									<li className="addresses__item">
										Rua Professor Paulo Roberto Martins, 91
										Bairro Santa Mônica - Florianópolis
									</li>
								</ul>
							</address>
						</div>
						<div className="footer__section">
							<span className="footer__title">Área do Aluno</span>
							<ul className="links">
								<li className="links__item">
									<a href="/">Curso Pedagogia Florença 1</a>
								</li>
								<li className="links__item">
									<a href="/">Curso Pedagogia Florença 2</a>
								</li>
							</ul>
						</div>
						<div className="footer__section">
							<span className="footer__title">Nossas redes</span>
							<ul className="social">
								<li className="social__item">
									<a href="/">Instagram</a>
								</li>
								<li className="social__item">
									<a href="/">Youtube</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</div >
	)
}
